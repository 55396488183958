.pincode-input-container {
  margin: 25px 0 !important;
}

.pincode-input-container .pincode-input-text {
  /* border: 2px solid !important; */
  border-radius: 8px !important;
  width: 39px !important;
  height: 39px !important;
  color: var(--neutral-60) !important;
  font-size: 24px !important;
  font-weight: bold !important;
}

.pin-input {
  width: 39px;
  height: 39px;
  padding: 0;
  border-radius: 8px;
  border: 2px solid var(--neutral-30);
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  color: var(--neutral-60);
  margin: 4px;
  outline: none;
  caret-color: transparent;
}

.pin-input:focus {
  border: 2px solid var(--neutral-60);
}

.enable-resend {
  /* background-color: var(--enabled); */
  font-size: 14px;
  margin-left: 10px;
  font-weight: bold;
  padding: 2px 15px;
  border-radius: 4px;
  cursor: pointer;
}
.disable-resend {
  color: var(--disabled);
  font-size: 14px;
  margin-left: 10px;
}
