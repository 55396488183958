* {
  box-sizing: border-box;
  margin: 0;
}

body {
  margin: 0;
  width: 100vw;
  height: 100vh !important;
  background-color: var(--neutral-20);
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Montserrat', BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --purple-60: #8e43c0;
  --purple-40: #b489e5;
  --mid-gray: #979797;
  --neutral-10: #fafaff;
  --neutral-20: #f1f1f9;
  --neutral-30: #cdcde1;
  --neutral-50: #9c9cc2;
  --neutral-60: #64648f;
  --neutral-70: #48486b;
  --green: #00cc96;
  --error: #f57a82;
  --warning: #feae2a;
  --background: #300a48;
  --off-white: #ffffff;
  --disabled: #bebdbd;
  --enabled: #f5f4fa;
}

.modal {
  width: 328px;
  /* height: 420px; */
  height: 495px;
  background-color: var(--off-white);
  border-radius: 20px;
  padding: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  /* position: relative; */
}
.content-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 100%;
}

.modal-title {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: var(--neutral-60);
  margin-bottom: 20px;
}

.modal-info {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: var(--neutral-70);
}

.button-container {
  margin-top: auto;
  width: 100%;
}

.primary-button {
  width: 100%;
  height: 50px;
  /* background-color: var(--purple-60); */
  color: var(--off-white);
  border-radius: 25px;
  margin-top: auto;
  border: none;
  font-weight: 500;
  cursor: pointer;
  font-family: inherit;
  font-size: 12px;
}
.cancel-button {
  margin: 10px 0;
  /* color: var(--purple-40); */
  font-size: 14px;
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
}

.shadow {
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
    0px 0px 1px rgba(9, 30, 66, 0.31);
}

/* text //////////////// */

.heading-large {
  font-weight: bold;
  font-size: 20px;
}
.heading-medium {
  font-weight: bold;
  font-size: 18px;
}
.heading-small {
  font-weight: bold;
  font-size: 16px;
}

.text-size-large {
  font-size: 18px;
}
.text-size-medium {
  font-size: 16px;
}
.text-size-small {
  font-size: 14px;
}
.text-size-xsmall {
  font-size: 12px;
}

.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}

.bold {
  font-weight: bold;
}
.semi-bold {
  font-weight: 500;
}

/* text colors //////////// */
.neutral-10 {
  color: var(--neutral-10);
}
.neutral-20 {
  color: var(--neutral-20);
}
.neutral-50 {
  color: var(--neutral-50);
}
.neutral-60 {
  color: var(--neutral-60);
}
.neutral-70 {
  color: var(--neutral-70);
}
.mid-gray {
  color: var(--mid-gray);
}
.warning {
  color: var(--warning);
}

/* DIMENSIONS  /////////// */
.w-100 {
  width: 100%;
}

/* margins ////////////// */
/* TOP  */
.mt-auto {
  margin-top: auto;
}
.mt-s {
  margin-top: 10px;
}
.mt-m {
  margin-top: 15px;
}
.mt-l {
  margin-top: 20px;
}
.m-top {
  margin-top: 20px;
}
/* BOTTOM */
.mb-auto {
  margin-bottom: auto;
}
.mb-s {
  margin-bottom: 10px;
}
.mb-m {
  margin-bottom: 15px;
}
.mb-l {
  margin-bottom: 20px;
}
.m-bot {
  margin-bottom: 10px;
}
/* LEFT */
.ml-auto {
  margin-left: auto;
}
.ml-s {
  margin-left: 10px;
}
.ml-m {
  margin-left: 15px;
}
.ml-l {
  margin-left: 20px;
}
/* RIGHT */
.mr-auto {
  margin-right: auto;
}
.mr-s {
  margin-right: 10px;
}
.mr-m {
  margin-right: 15px;
}
.mr-l {
  margin-right: 20px;
}

.m-auto {
  margin: auto;
}
.m-vertical {
  margin-left: 5px;
  margin-right: 5px;
}

/* POSITION  */
.relative {
  position: relative;
}
.absolute {
  position: absolute;
}

.back {
  position: absolute;
  left: 0;
  top: 6px;
}

.rigth {
  margin-left: auto;
  text-align: right;
}

.flex {
  display: flex;
  align-items: center;
}

.info-marketing {
  margin: auto auto -100px;
  color: #a5a5a5;
  font-size: 14px;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader-container {
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.long-time {
  text-align: center;
}
