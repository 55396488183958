.calculator {
  /* margin: auto 0; */
}

.calculator-container {
  width: 235px;
  height: 52px;
  margin: 8px auto 0;
  /* background-color: white; */
}

.calculator-inputs-container {
  display: flex;
  justify-content: space-between;
}
.calculator-label {
  margin-top: 5px;
  margin-right: 8px;
  margin-left: 8px;
}

.calculator-input {
  height: 20px;
  resize: horizontal;
  width: 64px;
}
